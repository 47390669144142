// Reload on `Failed to fetch dynamically imported module: ...`
export const preloadErrorHandler = () => {
  window.addEventListener('vite:preloadError', (event) => {
    const time = Number(window.location.hash.match(/#s(\d+)/)?.[1]);
    const now = Date.now();
    const shouldReload = !time || time + 10000 < now;

    if (shouldReload) {
      event.preventDefault();
      window.location.hash = `#s${now}`;
      window.location.reload();
    }
  });
};
